@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&display=swap);
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 99vh;
  width: 99vw;
  max-height: 99vh;
  max-width: 99vw;
}

.image {
  max-width: 99vw;
  max-height: 99vh;
  will-change: opacity;
  background-size: cover;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 99vw;
  max-height: 99hv;
  -webkit-user-select: none;
          user-select: none;
}

.logo {
  width: 25%; /* you can use % */
  height: auto;
}

p {
  font-size: 5vw;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

ul {
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

li {
  margin-bottom: 8px;
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

/* 
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  background: #f0f0f0;
}

#root {
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div {
  width: 400px;
}

.transitions-item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 5em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
} */

